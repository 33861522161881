import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from '@emotion/styled';
import { css, Theme } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

import Link from '@/components/atoms/Link';
import Typography from '@/components/atoms/Typography';
import Button from '@/components/atoms/Button';
import Hamburger from '@/components/atoms/Hamburger';

import mq from '@/styles/mq';
import LogoGuilmin from '@/svg/GuilminLogoFinal.svg';
import LinkDataType from '@/types/data/link';

interface NavLinkProps {
  link: LinkDataType;
  setOpenNav: Dispatch<SetStateAction<boolean>>;
}

interface Props {
  /**
   The links to show inside the menu
   */
  links: LinkDataType[];
  show?: boolean;
  contact: LinkDataType;
}

const NavRoot = styled('div')`
  display: block;
  height: 100%;
  ${mq('lg')} {
    display: none;
  }
`;
const HamburgerStyled = styled(Hamburger)`
  border-radius: 50%;
`;
const NavTrigger = styled(Button)`
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: ${({ theme }) => theme.zIndex.actions};

  // Change Hamburger spans background color
  // depending on open state and hover state
  ${HamburgerStyled} > span {
    background-color: ${({ color, theme }) =>
      color === 'primary'
        ? theme.color.white.main
        : theme.color.textPrimary.main};
  }

  &:hover ${HamburgerStyled} > span {
    background-color: ${({ color, theme }) =>
      color === 'primary'
        ? theme.color.textPrimary.main
        : theme.color.white.main};
  }
`;
const NavMenu = styled('div')`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.nav};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.color.primary.main};
  color: ${({ theme }) => theme.color.white.main};
  transform: translateX(150vw);
  transition: all 0.5s ease;
`;
const Logo = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(4)};
`;
const LinksContainer = styled('nav')`
  margin-top: ${({ theme }) => theme.spacing(4)};
`;
const NavList = styled('ul')``;
const Underline = styled('span')`
  display: block;
  width: 0;
  height: 2px;
  background-color: ${({ theme }) => theme.color.white.main};
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  transition: 0.3s ease-in-out;
`;
const NavItem = styled('li')`
  &:hover ${Underline} {
    width: 100%;
  }
`;
const NavLinkEl = styled(Link)`
  display: inline-block;
  width: 100%;
  font-size: 2rem;
  text-align: center;
  color: ${({ theme }) => theme.color.white.main};

  &:hover {
    color: ${({ theme }) => theme.color.white.main};
  }
`;
const DropdownMain = styled(NavItem)`
  display: inline-block;
  width: 100%;
  font-size: 2.8rem;
  text-align: center;
  color: ${({ theme }) => theme.color.white.main};
  cursor: pointer;
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  &:hover {
    color: ${({ theme }) => theme.color.white.main};
  }
`;
const ChevronDown = styled(FontAwesomeIcon)`
  font-size: 1.8rem;
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease;
`;
const DropdownBox = styled('div')`
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
`;
const DropdownLinkEl = styled(Link)`
  font-size: 2rem;
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  &:hover {
    color: ${({ theme }) => theme.color.white.main};
  }
`;

// Active classes
const openFab = css``;
const closeFab = (theme: Theme) => css`
  border-color: ${theme.color.white.main};
`;
const openedMenu = css`
  transform: translateX(0);
`;
const ShowDropdown = css`
  display: flex;
`;
const RotateChevron = css`
  transform: translateY(-50%) rotate(180deg);
`;
const DropdownMainOpen = css`
  margin-bottom: 0;
`;
const LogoLink = styled(Link)`
  display: block;
  margin-top: ${({ theme }) => theme.spacing(6)};
  margin-right: ${({ theme }) => theme.spacing(5)};
  svg {
    width: 190px;
    height: auto;
  }
  /* .st0 {
    fill: coral !important;
  } */
`;

const NavLink = ({ link, setOpenNav }: NavLinkProps): JSX.Element => {
  const { label, path } = link || {};

  const handleLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
  ): void | null => {
    // @ts-ignore
    return window.location.href === e.target.href ? setOpenNav(false) : null;
  };

  return (
    <NavItem>
      {path && (
        <NavLinkEl onClick={handleLinkClick} to={path}>
          {label}
        </NavLinkEl>
      )}
      <Underline />
    </NavItem>
  );
};

const DropdownLink = ({ link, setOpenNav }: NavLinkProps): JSX.Element => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  const { label, dropdown } = link || {};

  const handleOpenDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
  ): void | null => {
    // @ts-ignore
    return window.location.href === e.target.href ? setOpenNav(false) : null;
  };

  return (
    <>
      <DropdownMain
        onClick={handleOpenDropdown}
        css={openDropdown && DropdownMainOpen}
      >
        {label}{' '}
        <ChevronDown icon={faChevronDown} css={openDropdown && RotateChevron} />
      </DropdownMain>
      <DropdownBox css={openDropdown && ShowDropdown}>
        {dropdown?.map(
          (drop, i) =>
            drop.path && (
              <DropdownLinkEl
                key={i.toString()}
                to={drop.path}
                onClick={handleLinkClick}
              >
                {drop.label}
              </DropdownLinkEl>
            ),
        )}
      </DropdownBox>
    </>
  );
};

/**
 <h3>Display the logo and navigation links for mobile devices</h3>
 **/
const MobileNav = ({ links, show, contact }: Props): JSX.Element => {
  const [openNav, setOpenNav] = useState<boolean>(false);

  const Links = links.map((link, index) => {
    if (link.dropdown) {
      return (
        <DropdownLink
          key={index.toString()}
          setOpenNav={setOpenNav}
          link={link}
        />
      );
    }

    return (
      <NavLink key={index.toString()} setOpenNav={setOpenNav} link={link} />
    );
  });

  const handleOpen = () => {
    setOpenNav(!openNav);
  };

  return (
    <NavRoot>
      <NavTrigger
        onClick={handleOpen}
        variant="fab"
        color="primary"
        css={openNav ? closeFab : openFab}
      >
        <HamburgerStyled opened={openNav} />
      </NavTrigger>
      <NavMenu css={openNav && openedMenu}>
        <LogoLink to="/">
          <LogoGuilmin
            css={css`
              .st0 {
                fill: white !important;
              }
            `}
          />
          {/* <Logo
            id="Logo"
            variant="h3"
            color={invert && !navScrolled ? colorLogo : 'white'}
          >
            Guilmin
          </Logo> */}
        </LogoLink>
        {/* <Logo variant="h2" color="white">
          Guilmin
        </Logo> */}
        {show && (
          <LinksContainer>
            <NavList>{Links}</NavList>
          </LinksContainer>
        )}
        <Button
          size="sm"
          variant="outlined"
          color="white"
          as={Link}
          {...{ to: `${contact?.path}` }}
        >
          {contact?.label}
        </Button>
      </NavMenu>
    </NavRoot>
  );
};

export default MobileNav;
